// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-coursemap-js": () => import("./../../../src/pages/coursemap.js" /* webpackChunkName: "component---src-pages-coursemap-js" */),
  "component---src-pages-hole-hole-js": () => import("./../../../src/pages/hole/hole.js" /* webpackChunkName: "component---src-pages-hole-hole-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-js": () => import("./../../../src/pages/info.js" /* webpackChunkName: "component---src-pages-info-js" */),
  "component---src-pages-lost-js": () => import("./../../../src/pages/lost.js" /* webpackChunkName: "component---src-pages-lost-js" */),
  "component---src-pages-tournaments-js": () => import("./../../../src/pages/tournaments.js" /* webpackChunkName: "component---src-pages-tournaments-js" */)
}

